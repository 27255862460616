import {
  PaymentControllerService,
  PaymentMasterInfoVo,
} from '@/__generated__/CommonApi';
import btsImage from '@/assets/img/support/en_donate_bts@2x.png';
import Button from '@/components/Button';
import HR from '@/components/HR';
import { H2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper from '@/helpers/GtmHelper';
import { useFindSupportCategory } from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { FormContainer } from '@/page-blocks/auth/AuthCommon';
import SessionStorage from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const SupporterText = styled.span`
  color: #1cabe2;
`;

const ThankYouBox = styled.p`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -1.2px;
`;

const ContentBox = styled.div`
  margin-top: 68px;
`;

const PayDetail = styled.ul`
  padding-bottom: 24px;
  li {
    padding-left: 30%;
    position: relative;
    line-height: 28px;
    margin-top: 8px;
  }
  span {
    color: #2d2926;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
  }
  ${breakpoint(`mobile`)} {
    li {
      padding-left: 50%;
    }
  }
`;

const EnDonateCompletePage: FC<PageProps> = ({ location }) => {
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const orderNumber = params.get(`orderNumber`);
  const popupStore = usePopupStore();
  const userStore = useUserStore();

  const [order, setOrder] = useState<PaymentMasterInfoVo>();
  const supportAmount = order ? Number(order.totalAmount) : 0;
  const selectedCategory = useFindSupportCategory(
    order?.donationCode,
    order?.solicitCode,
  );

  useEffect(() => {
    // orderNumber가 없으면 튕기기
    if (!orderNumber) {
      popupStore.show(`잘못된 접근입니다`, {
        onConfirm: () => window.history.back(),
      });
    }
  }, [orderNumber, popupStore]);

  const loadOrder = useCallback(async () => {
    // 주문토큰 불러오기
    const orderToken = SessionStorage.getItem(`orderToken`);
    if (!orderToken) {
      popupStore.show(`후원 정보가 올바르지 않습니다`, {
        onConfirm: () => window.history.back(),
      });
    }
    if (orderNumber) {
      try {
        const {
          resultCode,
          resultMessage,
          data,
        } = await PaymentControllerService.getPaymentInfoUsingGet({
          orderNumber,
          orderToken,
        });
        // 성공이면
        if (resultCode === `success`) {
          // 데이터 세팅
          setOrder(data);
          return;
        }
        popupStore.show(resultMessage);
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            popupStore.show(e.response.data.resultMessage);
          } else if (e.response.status === 500) {
            popupStore.show(`서버 오류가 발생했습니다.`);
          }
        }
      }
    }
  }, [orderNumber, popupStore]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  // 로그인된 상태라면 회원정보 업데이트 (토큰 업데이트)
  useEffect(() => {
    if (userStore.isLoggedIn()) {
      userStore.refreshJwt();
    }
  }, [userStore]);

  // // 주문정보 로드되면 GTM 이벤트 전송
  // useEffect(() => {
  //   if (order) {
  //     // GTM transaction 이벤트 발생
  //     GtmHelper.transaction({
  //       orderNumber: order.orderNumber,
  //       supportAmount: order.totalAmount,
  //       supportCategory: selectedCategory,
  //       memberTypeCode: order.memberTypeCode,
  //       paymentType: order.paymentTypeCode,
  //     });
  //   }
  // }, [order, selectedCategory]);

  return (
    <LayoutWithTitle location={location} title="Donate">
      <FormContainer
        css={`
          margin-top: 96px;
        `}
      >
        <ThankYouBox>
          Dear <SupporterText>{order?.memberName}</SupporterText>,
          <br />
          Thank you for helping us
          <br />
          to make a better world without violence.
        </ThankYouBox>
        <ContentBox>
          <H2>Application History</H2>
          <HR color="#1cabe2" />
          <img
            src={btsImage}
            alt="BTS ENDviolence"
            width="100%"
            css={`
              margin-top: 24px;
            `}
          />
          <PayDetail>
            <li className="ellipsis">
              <span>One-time donation</span>
              #ENDviolence
            </li>
            <li>
              <span>Credit card</span>
              {supportAmount.toLocaleString()} won
            </li>
          </PayDetail>
          <HR />
        </ContentBox>
        <Button
          full
          onClick={() => navigate(`/`)}
          css={`
            margin-top: 108px;
            margin-bottom: 115px;
          `}
        >
          home
        </Button>
      </FormContainer>
    </LayoutWithTitle>
  );
};

export default EnDonateCompletePage;
